import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { FC } from '@publica/ui-common-utils'
import { NotInOfficePage } from '@publica/ui-office-components'
import { setEnvironment } from '@publica/ui-office-utils'

// FIXME-P1(word-ui)
// https://console.cloud.google.com/logs/query;cursorTimestamp=2023-06-22T13:41:19.936Z;query=labels.platform%3D%22publica%22%0A-%2528%2528jsonPayload.payload.path%3D%22%2Fhealth%2Freadiness%22%20OR%20jsonPayload.message%3D%22GET%20%2Fhealth%2Fliveness%22%2529%2529%0Alabels.app%3D%22word-ui%22%0Atimestamp%3D%222023-06-22T13:41:19.931000064Z%22%0AinsertId%3D%22ln6qfpfjtzqtf%22;summaryFields=labels%252Fapp:false:32:beginning;timeRange=P7D?project=fp-publica-prod-yuz

setEnvironment('main', false)

const App: FC = () => (
    <StrictMode>
        <NotInOfficePage app="Word" />
    </StrictMode>
)

const root = createRoot(document.getElementById('container')!)

root.render(<App />)
